export default {
  'customer.name': 'Quản lý khách hàng',
  'customer.customerName': 'Tên khách hàng',
  'customer.customerEmail': 'Email',
  'customer.customerPhone': 'Số điện thoại',
  'customer.customerAddress': 'Địa chỉ',
  'customer.customerCoin': 'Số tiền',
  'customer.customerCard': 'Thẻ',
  'customer.action': 'Hành động',
  'customer.internalComment': 'Ghi chú',
  'customer.update': 'Cập nhật khách hàng',
  'customer.create': 'Thêm khách hàng',
  'customer.password': 'Mật khẩu',
  'customer.customerCode': 'Mã code',
  'customer.customerBirthday': 'Ngày sinh',
  'customer.customerVerification': 'Trạng thái xác nhận',
  'customer.isActive': 'Trạng thái hoạt động',
  'customer.statusConfirm': 'Đã xác nhận',
  'customer.statusUnConfirm': 'Chưa xác nhận',
  'customer.information': 'Thông tin',
  'customer.delete': 'Xóa khách hàng',
  'customer.content.delete': 'Bạn có chắc chắn muốn xóa khách hàng này không?',
  'customer.amountEntry': 'Checking',
  'customer.customerAvatar': 'Avatar',
  'entryLog.logName': 'Tên nhật ký',
  'entryLog.state.times': `Checking {times, plural,  
    =0 {lần đầu.}
    =1 {lần đầu.} 
    other {lần thứ #.}}`,
  'entryLog.state': 'Trạng thái',
  'entryLog.state.wrong': 'Sai cổng checking',
  'entryLog.timeLog': 'Thời gian nhật ký',
  'logs.name': 'Nhật ký',
  'logs.timeLog': 'Tìm kiếm theo ngày',
  'customer.customerCard.null': 'Chưa có thẻ',
  'customer.customerCard.not-null': 'Đã có thẻ',
  'customer.ticketType': 'Hạng vé',
  'customer.import': 'Thêm danh sách khách hàng',
  'customer.attendeeName': 'Tên người tham dự',
  'customer.orderNumber': 'Mã đơn hàng',
  'customer.confirmEmail': 'Trạng thái gửi email',
  'customer.confirmEmail.isSend': 'Đã gửi email',
  'customer.confirmEmail.not-send': 'Chưa gửi email',
  'customer.customerEmail.status': `{send, plural,  
    =0 {Chưa gửi email: }
    =1 {Đã gửi email: }
    other {lần thứ #.}} {email}`,
  'customer.card.amountCustomers': 'Khách hàng đã checkin',
  'customer.card.amountEntry': 'Lượt checkin',
};
